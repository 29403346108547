<template>
	<v-container class="SignAccount mt-10">
	
		<div class="mt-10"></div>
		
		<v-text-field
			v-model="item.id"
			label="아이디"
			placeholder="4자리 이상 영문자, 숫자만 이용 가능"
			color="success2"
			type="email"
			required
			
			:maxlength="40"
			:rules="[rules.id]"
			:success-messages="messages.id"
			:error="errors.id ? true : false"
			:error-messages="errors.id ? errors.id_message : ''"
			
			@blur="checkId"
		>
		</v-text-field>
		
		<div class="mt-5"></div>
		
		<v-text-field
			v-model="item.password"
			label="비밀번호"
			placeholder="비밀번호를 입력하세요"
			color="success2"
			
			:rules="[rules.password]"
			
			:maxlength="40"
			required
			
		
			:append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
			:type="show1 ? 'text' : 'password'"
			@click:append="show1 = !show1"
		>
		</v-text-field>
		
		<div class="mt-5"></div>
		
		<v-text-field
			v-model="password"
			label="비밀번호 확인"
			placeholder="비밀번호를 다시 입력하세요"
			color="success2"
			
			:error="errors.pw ? true : false"
			:error-messages="errors.pw ? errors.pw_message : ''"
			
			:rules="[rules.confirm]"
			
			:maxlength="40"
			required
			
			:append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
			:type="show2 ? 'text' : 'password'"
			@click:append="show2 = !show2"
		>
		</v-text-field>
		
		<div class="mt-5"></div>
		
		<v-text-field
			v-model="item.email"
			label="이메일"
			placeholder="이메일을 입력하세요"
			color="success2"
			type="email"
			required
		
			:maxlength="40"
			:rules="[rules.email]"
			:success-messages="messages.email"
			:error="errors.email ? true : false"
			:error-messages="errors.email ? errors.email_message : ''"
			
			@blur="checkEmail"
		>
		</v-text-field>
		
	</v-container>
</template>
<script>

	export default{
		name: 'SignAccount'
		,created: function(){
			this.$emit('setProgram', this.program)
		}
		,props: ['callBack']
		,data: function(){
			return {
				program: {
					title: '회원가입'
					,not_navigation: true
					,next: {
						title: '완료'
						,do: true
						,callBack: 'signUp'
					}
				}
				,show1: false
				,show2: false
				,item: {
					id: ''
					,password: ''
					,email: ''
				}
				,password: ''
				,rules:{
					id: value => {
						
						const min = 4
						const max = 40
						const val = /^[a-z]+[a-z0-9]{4, 40}$/;
						
						const high = value.search(/[A-Z]/ig);
						const low = value.search(/[a-z]/ig);
						const num = value.search(/[0-9]/g);
						const kor = value.search(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/ig)
						const spe = value.search(/[`~!@#$%^&*()-_=+|₩₩₩'₩";:₩/?]/gi);
						const check = value.search(/^[A-Za-z0-9+]{4, 40}$/)
						
						console.log(check)
						if(val.test(value)){
						console.log('check val')
							this.errors.id = true
							return this.errors.id_message = '영문/숫자 조합 ' + min + ' ~ ' + max + '조합'
						}else{
							console.log('uncheck')
						}
					
						
						if(value.length <= 0){
							this.errors.id = false
							return ''
						}
						
						if(kor > -1){
							this.errors.id = true
							return this.errors.id_message = '한글은 사용할 수 없습니다.'
						}
						
						if(high != 0 && low != 0){
							this.errors.id = true
							return this.errors.id_message = '아이디 첫글자는 영문자를 포함해야합니다'
						}
						
						if(num < 0){
							this.errors.id = true
							return this.errors.id_message = '영문/숫자로 조합'
						}
						
						if(value.length < min){
							this.errors.id = true
							return this.errors.id_message = '영문/숫자로 조합 ' + min + '~' + max + '자리'
						}
						
						if(value.length > max){
							this.errors.id = true
							return this.errors.id_message = '영문/숫자로 조합 ' + min + '~' + max + '자리'
						}
						
						if(high < 0 && low < 0){
							this.errors.id = true
							return this.errors.id_message = '영문자를 포함해야합니다'
						}
						
						if(spe > 0){
							this.errors.id = true
							return this.errors.id_message = '특수문자는 사용할 수 없습니다'
						}else{
							this.errors.id = false
							return true
						}
					}
					,password: value => {
						
						const min = 8
						const max = 40
						const num = value.search(/[0-9]/g);
						const high = value.search(/[A-Z]/ig);
						const low = value.search(/[a-z]/ig);
						const spe = value.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);
						
						if(value.length < min){
							this.errors.pw = true
							return '영문 대소문자 + 숫자 + 특수문자 조합 8~40자리'
						}
						if(value.length > max){
							this.errors.pw = true
							return '영문 대소문자 + 숫자 + 특수문자 조합 40자리 이하'
						}
						if(num < 0){
							this.errors.pw = true
							return '영문 대소문자 + 숫자 + 특수문자 조합 필요'
						}
						if(high < 0){
							this.errors.pw = true
							return '영문 대문자 + 특수문자 조합 필요'
						}
						if(low < 0){
							this.errors.pw = true
							return '영문 소문자 + 특수문자 조합 필요'
						}
						if(spe < 0){
							this.errors.pw = true
							return '특수문자 조합 필요'
						}
						
						if(value.length < min || value.length > max || num < 0 || high < 0 || low < 0 || spe < 0){
							this.errors.pw = true
							return '영문 대소문자 + 숫자 + 특수문자 조합 8~40자리'
						}else{
							this.errors.pw = false
							return true
						}

					}
					,confirm: value => {
						if(value){
							if(this.item.password != value){
								return '비밀번호를 확인하세요'
							}else{
								return true
							}
						}else{
							this.errors.pw = false
							return true
						}
					}
					,email: value => {
						const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
						
						if(value){
							if(pattern.test(value)){
								this.errors.email = false
								return true
							}else{
								this.errors.email = true
								return '메일 양식이 아닙니다'
							}
						}else{
							this.errors.email = false
							return true
						}
					}
				}
				,errors: { 
					id: false
					,id_message: '아이디를 입력하세요'
					,pw: false
					,pw_message: '비밀번호를 입력하세요'
					,email: false
					,email_message: '이메일을 입력하세요'
				}
				,sample: {
					id: 'coehdus'
				}
				,messages: {
					id: ''
					,password: ''
					,confirm: ''
					,email: ''
				}
			}
		}
		,methods: {
			
			signUp: function(){
				this.$emit('axios', {
					request_type: 'post'
					,request_url: '/signup'
					,request_params: {
						email: this.item.email
						,password: this.item.password
						,userId: this.item.id
					}
					,callBack: 'result'
				})
			}
			,result: function(call){
				console.log('account result')
				if(call.result){
					this.$emit('modal', {
						icon: 'mdi-check-circle'
						,icon_color: 'success2'
						,title: '회원가입 완료'
						,contents: this.item.id + '님 회원가입 신청이 완료되었습니다'
						,accept: '확인'
						,to: '/Login'
					})
				}else{
					this.$emit('alertMsg', 'error', call.items.message)
				}
			}
			,checkId: function(){
				if(this.item.id){
					if(!this.errors.id){
						this.$emit('axios', {
							request_type: 'get'
							,request_url: '/signup/checked/id/' + this.item.id
							,callBack: 'resultId'
						})
					}
				}
			}
			,resultId: function(call){
				if(call.result){
					this.errors.id = false
					this.messages.id = call.items.message
				}else{
					this.messages.id = ''
					this.errors.id = true
					this.errors.id_message = call.items.message
				}
				
				this.checkForm(this.item)
			}
			,checkEmail: function(){
				if(this.item.email){
					if(!this.errors.email){
						this.$emit('axios', {
							request_type: 'get'
							,request_url: '/signup/checked/email/' + this.item.email + '/'
							,callBack: 'resultEmail'
						})
					}
				}
			}
			,resultEmail: function(call){
				console.log('resultemail')
				console.log(call)
				if(call.result){
					this.errors.email = false
					this.messages.email = call.items.message
				}else{
					this.messages.email = ''
					this.errors.email = true
					this.errors.email_message = call.items.message
				}
				
				this.checkForm(this.item)
			}
			,checkForm: function(call){
				
				if(this.errors.id || (call.id == undefined || call.id == '')){
					this.program.next.do = false
					return false
				}else if(this.errors.pw || (call.password == undefined || call.password == '')){
					this.program.next.do = false
					return false
				}else if(this.errors.email || (call.email == undefined || call.email == '')){
					this.program.next.do = false
					return false
				}else{
					this.program.next.do = true
				}
			}
		}
		,watch: {
			callBack: {
				deep: true
				,handler: function(call){
					console.log('watch help signaccount')
					console.log(call)
					if(call.name == 'result'){
						this.result(call)
					}else if(call.name == 'signUp'){
						this.signUp()
					}else if(call.name == 'resultId'){
						this.resultId(call)
					}else if(call.name == 'resultEmail'){
						this.resultEmail(call)
					}
				}
			}
			,item: {
				deep: true
				,handler: function(call){
					this.checkForm(call)
				}
			}
			,errors: {
				deep: true
				,handler: function(call){
					if(call){
						this.program.next.do = false
					}
				}
			}
		}
	}
</script>
<style>
	.SignAccount .v-label {font-size: 1.2em !important; font-weight: bold; color: black !important; top: -5px !important;}
</style>







